<template>
  <div>
      <Slider />
      <Form />
  </div>
</template>

<script>
export default {
    name: 'Sales',
    components: {
       Slider: () => import('./slider'),
       Form: () => import('./salesForm'),

    },

}
</script>

<style>

</style>